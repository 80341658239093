
 if( 'undefined' === typeof window){
  importScripts("https://www.gstatic.com/firebasejs/9.1.3/firebase-app-compat.js");
  importScripts("https://www.gstatic.com/firebasejs/9.1.3/firebase-messaging-compat.js");
   }

firebase.initializeApp({
  projectId: 'autlan-51170',
  appId: '1:634955475499:web:5d87752071733e3c14586e',
  storageBucket: "autlan-51170.appspot.com",
  apiKey: "AIzaSyAWEGWaFNXSbm-8qSyMtShUIy-CkJ6-pWM",
  authDomain: "autlan-51170.firebaseapp.com",
  messagingSenderId: "634955475499",
  measurementId: 'G-1W0NN5ZFKH',
  vapidKey: 'BJ-T9gj7RldK_NLQF_PLvJRxMnjoHKpGkOFihC4QDUCCKKVilPe6U_pvDpW5_JRxcnGY4AsqWOla5wPd53leoK8'
});

const messaging2 = firebase.messaging();
